.categories {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--spacing-4);

    width: 100%;

    @media --sm {
        grid-template-columns: repeat(2, 1fr);
    }
}

.category {
    position: relative;
    min-height: 350px; /* TODO Variable */
    @media --sm {
        min-height: 500px; /* TODO Variable */
    }
}
.category::after {
    content: '';
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(52, 51, 51, 1) 95%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.content {
    position: absolute;
    bottom: var(--spacing-5);
    left: var(--spacing-4);
    right: var(--spacing-3);
    padding-bottom: var(--spacing-5);
    z-index: 1;
}

.link {
    color: currentColor;
    text-decoration: none;
    @mixin cover;
    top: initial;
}

.link:is(:hover, :focus) {
    text-decoration: underline;
}

